.MyProfileCard{
    display: flex;
    flex-direction: column;
    background: var(--cardColor);
    border-radius: 0.5rem;
    padding: 2rem;
}

.MyProfileCard>div:nth-of-type(1){
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MyProfileCard>div:nth-of-type(2){
    display: flex;
    flex-direction: column;
}

.lg-button{
    padding: 0.5rem 1rem;
    width: 50%;
    margin-top: 2rem;
   
}

.detail>button{
    align-self: flex-end;
}