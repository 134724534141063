.Profile{
    display: grid;
    gap: 1rem;
    grid-template-columns: 20rem auto 20rem;
    position: relative;   
   
}

.ProfileLeft{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.ProfileCenter{
    display: flex;
    flex-direction: column;
    gap: 1rem  ;
    overflow: auto;
    max-height: 100vh;
    overflow-y: scroll;
}


@media screen and (max-width:1100px){
    .Profile{
        
        grid-template-columns:  auto 20rem;
     
    }

    .ProfileLeft{
        display:none
    }

}
@media screen and (max-width:930px){
    .Profile{        
        grid-template-columns:  auto;     
    }

    .ProfileLeft{
        display:none
    }
}
