.RightSide {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1;
}

.navIcons {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
}

.dropdownPopupItem {
  display: flex !important;
  gap: 0.5rem !important;
  align-items: center !important;
}

.navIcons > img {
  width: 1.5rem;
  height: 1.5rem;
}

.navIcons > img:hover,
svg:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.sh-button {
  padding: 0.7rem;
  font-size: 1rem;
  width: 80%;
  align-self: center;
}
