.LogoSearch{
    display: flex;
    gap: 0.2rem;
    width: 90%;
    align-items: center;
}

.logoIcon>img{
    width: 3rem;
    height: 3rem;
    position: relative;
}

.Search{
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: min-content;
    justify-content: space-between;
    
}

.Search>input{
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding-right: 0.5em;
}

.s-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
   background: var(--buttonBg) ;  /*linear-gradient(106.23deg, #f99827, #f95f35 100%); */
    border-radius: 5px;
    color: white;
}

.s-icon:hover{
    cursor: pointer;
}