.FollowersCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
}

.follower {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.follower > div {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.followername {
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
}

.followername > span:nth-of-type(1) {
  font-weight: bold;
}

.follower > div > img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.f-button {
  padding: 0.2rem 1rem;
}
