:root {
  /* --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #EF5757;
  --shedule: #E1AE4A; */



  --yellow: #0B7781;
  --orange: #3db6c1;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #3db6c1 0%, #0B7781 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #6db3ba;
  --shedule: #64c7d0;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  overflow: -moz-scrollbars-none;
  /* for old fire fox scroll bars*/
}

.App {
  overflow: hidden;
  color: var(--black);
  background-color: #f3f3f3;

  display: flex;
  flex-direction: column;

}


.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background-color: #92ddf0;
  filter: blur(72px);
}



.button {
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 0.5rem;
  align-items: center;
  background: var(--buttonBg);
  border: 2px solid;
  transition: all 100ms ease-in-out;
}

.button:hover {
  cursor: pointer;
  border: 2px solid var(--orange);
  background: none;
  color: var(--orange);
}

::-webkit-scrollbar {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}
.container{
  padding-top: 00.5rem !important;
  max-width: 100% !important;
}

@media screen and (max-width:768px) {
  .mobileLauncher {
    /* align-self: flex-end; */
    background-color: white;
    /* position: absolute; */
    /* bottom: 0px; */
  }

  

  .mainAppSections {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 100vh ;
  }
 
  .App{
    /* position: relative; */
    margin-bottom: auto;
   height:100%;
  }

  .mainAppSections>:nth-child(2) {
    position: fixed;
    /* height: 10vh; */
    width: 100%;
    bottom: 0;
    justify-content: center;
}

/* customising the comment section */
.overlay{
  padding: 3px !important;
}
 
.overlay>div>form{
  padding: 3px !important;
}
.replysection>div>form{
  padding: 3px !important;
}
.postBtn{
  padding: 2px 3px !important;
  margin-left: 3px !important;
}
.cancelBtn{
  padding: 2px 3px !important;
  margin-left: 3px !important;
}

.userImg{
  margin: 0px 2px !important;
}

  /* .ChatBox-container{
    height: 90vh;
  } */
  /* .chat-sender{
    position: absolute;
    bottom: 0px;
    width: 100%;
  } */
}