.PostSide{
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1rem;
    width: 100%;
    border-radius: 1rem;
    max-height: 100vh;

    scrollbar-width: none;
    /* Firefox */

    overflow: -moz-scrollbars-none;
    /* for old fire fox scroll bars*/
    }