.Home2 {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    position: relative;
}

/* .Home>:nth-child(1){
    flex: 1;
    
}
.Home>:nth-child(2){
    flex: 3;
    
}
.Home>:nth-child(3){
    flex: 1;
    
} */
.hideMobile {
    display: block;
}

@media screen and (max-width:1100px) {
    .Home {
        grid-template-columns: auto 20rem;
    }

    .ProfileSide {
        display: none
    }

}

@media screen and (max-width:930px) {
    .Home {

        grid-template-columns: auto;

    }
    .ProfileSide {
        display: none
    }

    .RightSide {
        display: none
    }

}

@media screen and (max-width:768px) {

    /* .PostShare  {
        display:none
    } */

    .hideMobile {
        display: none;
    }

    /* .Auth {
        max-height: 90vh;
    } */

    /* .ProfileCenter {
        max-height: 90vh;
    } */

    /* .PostSide {
        max-height: 90vh;
    } */

    .App {
        display: flex;
        flex-direction: column;
        /* justify-content: end; */
        padding-top: 1rem;
    }


    .navIcons {
        justify-content: space-around;
        background-color: white;
    }

    .isMobileSearch {

        display: grid;
        gap: 0rem;
        grid-template-columns: auto !important;
        position: relative;



    }


}