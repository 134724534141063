.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 22% auto;
  gap: 1rem;
}

.Left-side-chat {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  max-height: 100vh;
  overflow-y: scroll;
}

.Chat-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--cardColor);
  border-radius: 1rem;
  padding: 1rem;
  height: 80vh;
  overflow: scroll;
}

.Chat-list {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
 
}

.conversation:hover {
  background: #80808038;
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}

.online-dot {
  background-color: greenyellow;
  border-radius: 50%;
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
}
.conversation:hover {
  background: #80808038;
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}
.follower{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.follower>div{
    display: flex;
    gap: 10px;
}

.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}


@media screen and (max-width: 768px) {
 .Chat {
    grid-template-columns: 16% auto;
  }
   .follower.conversation>div>.name{
    display: none;
   }
}
@media screen and (max-width:760px){
  .Chat {
    
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
   .follower.conversation>div>.name{
    display: block;
   }
   .Right-side-chat{
    display:none
   }
 
   .Left-side-chat2{
    display: none;
    }
    
    .floatingChatbtn {
      padding: 0.5rem;    
      border-radius: 50%;
      background-color: white;
      position: absolute;
      right: 2rem;
      bottom: 4rem;
      box-shadow: 4px 4px 4px;
      border: 0.5px solid grey;
    
    }
    .floatingChatbtn:hover{
      background-color: transparent;
    }
   /* .App{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
   } */
}